import Vue from 'vue'
import vuetify from './plugins/vuetify'
import VueCookie from 'vue-cookie'
import VueRouter from 'vue-router'

// widgets import
import ProxyCalculatorWidget from './widgets/ProxyCalculatorWidget.vue'
import LoginWidget from './widgets/LoginWidget.vue'
import FeedbackWidget from '@/widgets/FeedbackWidget.vue'
import SiteWidget from '@/widgets/SiteWidget.vue'
import ProxyCheckerWidget from '@/widgets/ProxyCheckerWidget.vue'
import OsFingerprintWidget from '@/widgets/OsFingerprintWidget.vue'
import ProxyFingerprintWidget from '@/widgets/ProxyFingerprintWidget.vue'
import PageNotificationsWidget from '@/widgets/PageNotificationsWidget.vue'

// import './sass/main.scss'
import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false

Vue.use(VueRouter)

Vue.use(VueCookie)

const widgets = {
  '#proxy-calculator': {
    component: ProxyCalculatorWidget
  },
  '#login-widget': {
    component: LoginWidget
  },
  '#partner-proxy-calculator': {
    component: ProxyCalculatorWidget,
    data: {
      props: {
        priceType: 'partner'
      }
    }
  },
  '#feedback-widget': {
    component: FeedbackWidget
  },
  '#site-config': {
    component: SiteWidget
  },
  '#proxy-checker-widget': {
    component: ProxyCheckerWidget
  },
  '#os-fingerprint': {
    component: OsFingerprintWidget
  },
  '#proxy-fingerprint': {
    component: ProxyFingerprintWidget
  },
  '#page-notifications-widget': {
    component: PageNotificationsWidget
  }
}

for (const id of Object.keys(widgets)) {
  if (document.getElementById(id.slice(1))) {
    const component = widgets[id].component
    const data = widgets[id].date || {}
    const options = {
      vuetify,
      render: h => h(component, data)
    }
    new Vue(options).$mount(id)
  }
}
