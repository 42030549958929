<template>
  <div class="v-application v-application--is-ltr" style="margin: 1em">
    <v-row class="container">
      <v-banner
        v-for="item in filteredItems"
        :key="item.id"
        class="page-notification-banner"
        elevation="4"
        rounded
        single-line
        dark
      >
        <div v-html="item.message" class="page-notification-message" />
        <template v-slot:actions>
          <v-btn icon @click="close(item)">
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </v-banner>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PageNotificationsWidget',
  data () {
    return {
      items: [],
      closed: [],
      storageKey: null
    }
  },
  computed: {
    filteredItems () {
      return this.items.filter(i => !this.closed.includes(i.id))
    }
  },
  async mounted () {
    const path = window.location.pathname
    this.storageKey = `page:${path}:closed-notifications`
    this.closed = JSON.parse(sessionStorage.getItem(this.storageKey) || '[]')
    await this.connect()
  },
  methods: {
    async connect () {
      const ws = new WebSocket(process.env.VUE_APP_OFFICE_NOTIFICATIONS_WS)
      ws.onopen = () => {
        const data = {
          type: 'page-notifications',
          url: window.location.pathname.substring(1),
          target: 'site'
        }
        ws.send(JSON.stringify(data))
      }
      ws.onmessage = (e) => {
        const d = JSON.parse(e.data)
        if (d?.type === 'page-notifications') {
          this.items = d.items
        }
      }
    },
    async close (item) {
      this.closed.push(item.id)
    }
  }
}
</script>

<style>
.page-notification-banner {
  background-color: #272727 !important;
  border-color: #272727 !important;
  color: white !important;
  margin-bottom: 1em;
}

.page-notification-message > p {
  margin-bottom: 0 !important;
}
</style>
