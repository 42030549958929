import { render, staticRenderFns } from "./ProxyCalculatorWidget.vue?vue&type=template&id=28e5e726&"
import script from "./ProxyCalculatorWidget.vue?vue&type=script&lang=js&"
export * from "./ProxyCalculatorWidget.vue?vue&type=script&lang=js&"
import style0 from "./ProxyCalculatorWidget.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ProxyCalculatorWidget.vue?vue&type=style&index=1&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VBtnToggle,VCard,VForm,VProgressCircular,VSelect,VSlider,VTextField})
