import request from './../utils/request'

export const ConfigApi = {
  baseUrl: '/site/v1/config',

  classifyUrl () {
    return request({
      url: `${this.baseUrl}/classify-url/`,
      method: 'get'
    })
  }
}
