<template>
  <div data-app id="proxy-calculator">
    <div class="calculator-container">
      <v-card elevation="2" class="p-3">
          <div v-if="error" class="my-5">
            {{ error }}
          </div>
          <v-progress-circular v-else-if="loading" indeterminate class="my-5" />
          <div v-else>
            <div v-if="serviceTypes.length > 1">
              <v-select v-model="serviceType" label="Вид услуги" :items="serviceTypes" item-text="name" item-value="key" hide-details="auto"/>
            </div>

            <div v-if="types.length > 0">
              <v-btn-toggle v-if="false" v-model="type" mandatory dark @change="loadLocations">
                <v-btn v-for="item in types" :key="item.key" :value="item.key">
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
              <v-select v-else v-model="type" label="Тип услуги" :items="types" item-text="name" item-value="key" hide-details="auto" :disabled="types.length === 0" @input="loadLocations" />
            </div>

            <v-select v-model="country" label="Страна" :items="countries" item-text="name" item-value="key" hide-details="auto" @input="loadTypes" />
            <v-select :loading="locationLoading" v-model="location" label="Регион" :items="locations" item-text="name" item-value="key" hide-details="auto" :disabled="country === null || locations.length === 0" @input="loadOperators" />
            <v-select v-if="false" :loading="operatorLoading" v-model="operator" label="Оператор" :items="operators" item-text="name" item-value="key" hide-details="auto" :disabled="location === null || operators.length < 2 || type === 'arbitration'" @input="change" />

            <div v-if="stacks.length > 0">
              <v-btn-toggle v-if="useButtons" v-model="stack" mandatory dark @change="change">
                <v-btn v-for="item in stacks" :key="item.key" :value="item.key">
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>
              <v-select v-else v-model="stack" label="Операционная система" :items="stacks" item-text="name" item-value="key" hide-details="auto" :disabled="location === null" @input="change" />
            </div>

            <v-form ref="form">
              <v-select v-model="dnsType" label="Тип DNS" :items="dnsTypes" item-text="name" item-value="key" hide-details="auto" />
              <v-text-field v-if="dnsType === 'user_dynamic'" v-model="dnsValue" label="DNS сервера через запятую" hide-details="auto" :rules="[val => validateDnsValue(val) || 'Введите адреса DNS серверов']"/>
            </v-form>

            <div v-if="type !== null">
              <h4 style="margin-top: .5em">Срок аренды</h4>
              <v-text-field v-if="false" v-model="period" type="number" min="1" @input="calculate" />
              <v-slider v-if="false" v-model="period" :min="periodLimits.min" :max="periodLimits.max" step="1" thumb-label="always" thumb-color="#272727" :disabled="location === null" persistent-hint style="margin-top: 2.3em" @change="calculate">
                <template v-slot:thumb-label>
                  {{ periodLabel }}
                </template>
              </v-slider>
              <v-btn-toggle v-model="period" mandatory dark @change="calculate">
                <v-btn v-for="item in periods" :key="item.key" :value="item.key">
                  {{ item.name }}
                </v-btn>
              </v-btn-toggle>

              <v-slider v-model="amount" min="1" :max="maxProxy" step="1" label="Количество" thumb-label="always" thumb-color="#272727" style="margin-top: 2.2em" :disabled="location === null" persistent-hint @change="calculate"  />

              <div v-if="type !== 'arbitration'">
                <v-slider v-model="rotationPeriod" :min="0" :max="30" step="1" label="Ротация (мин)" thumb-label="always" thumb-color="#272727" ersistent-hint />
              </div>

              <div v-if="false">
                <h4>Тариф</h4>
                <v-select v-model="tariff" label="Выберите тариф" :items="tariffs" item-text="name" item-value="id" style="padding-top: 0" single-line @input="calculate" />
              </div>

              <v-btn v-if="false" depressed dark color="primary" class="w-100" :disabled="calculateButtonDisabled" @click="calculate">Посчитать</v-btn>

              <v-progress-circular v-if="priceLoading" indeterminate class="my-4" />
              <div v-else-if="price > 0">
                <div v-if="price > 0" class="calculator-price">
                  <div>
                    <span v-if="basePrice > price" :class="$style.basePrice">{{ basePrice.toLocaleString() | formatPrice }} ₽</span>
                    <span style="position: relative; left: -20px">{{ price.toLocaleString() | formatPrice }} ₽</span>
                  </div>
                  <div v-if="recommendations && priceType === 'client'" class="recommendations" v-html="recommendations" />
                </div>
                <v-btn v-if="this.template !== null && priceType === 'client'" depressed dark color="primary" class="w-100" @click="buy">Купить</v-btn>
              </div>
              <div v-else-if="recommendations && priceType === 'client'" class="recommendations">
                К сожалению, по данным параметрам не осталось ни одного прокси. Пожалуйста, измените параметры, чтобы оформить заказ.
              </div>
              <v-btn v-if="priceType === 'partner'" depressed dark color="primary" class="w-100" @click="register">Получить статус реселера</v-btn>
            </div>
          </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { ConfiguratorApi } from '@/api/calculator'
import { info } from '@/api/user'
// import { addOrderToCart } from '@/utils/cart'

const operators = {
  rt: 'Ростелеком',
  tele2: 'Tele2',
  yota: 'Yota',
  megafon: 'Megafon',
  mts: 'MTS',
  beeline: 'Beeline',
  vodafone: 'Vodafone',
  kievstar: 'KievStar',
  lifecell: 'LifeCell',
  common: 'Случайный'
}

function validIpAddress (value) {
  const re = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return re.test(value)
}

const countries = {
  ru: 'Россия',
  ua: 'Украина'
}

const serviceTypes = [
  { key: 'mobile', name: 'Мобильные прокси' }
]

const periods = [
  { key: 'h', name: 'Час' },
  { key: 'd', name: 'День' },
  { key: 'w', name: 'Неделя' },
  { key: 'm', name: 'Месяц' },
  { key: 'y', name: 'Год' }
]

export default {
  name: 'ProxyCalculatorWidget',
  filters: {
    formatPrice (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  },
  props: {
    priceType: {
      type: String,
      default: 'client'
    }
  },
  data () {
    return {
      active: false,
      loading: true,
      priceLoading: false,
      error: null,
      useButtons: false,
      country: null,
      operator: null,
      location: null,
      type: null,
      stack: null,
      periods,
      period: 'm',
      countries: [],
      operators: [],
      locations: [],
      types: [],
      stacks: [],
      maxProxy: 0,
      amount: 1,
      template: null,
      basePrice: 0,
      price: 0,
      priceLoadingTimeoutHandler: null,
      recommendations: '',
      locationLoading: false,
      operatorLoading: false,
      serviceTypes,
      serviceType: serviceTypes[0].key,
      rotationPeriod: 10,
      dnsType: 'provider',
      dnsValue: '',
      periodLimits: {
        min: 1,
        max: 90
      },
      availableProxies: {},
      locationNames: [],
      protocol: 'https',
      tariffs: [],
      tariff: null,
      nextTariff: '',
      proxySubTypes: []
    }
  },
  computed: {
    calculateButtonDisabled () {
      return this.serviceType === null || this.type === null || this.stack === null || this.location === null || this.operator === null || this.amount === 0 || parseInt(this.period) <= 0
    },
    dnsTypes () {
      const items = [
        { key: 'operator', name: 'Нативный' },
        { key: 'provider', name: 'Провайдерский' },
        { key: 'user_dynamic', name: 'Пользовательский' }
      ]

      if (!items.some(i => i.key === this.dnsType)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dnsType = items[0].key
      }

      return items
    },
    periodLabel () {
      if (this.period < 24) {
        return `${this.period} ч.`
      }
      return `${this.period - 23} д.`
    }
  },
  watch: {
    async serviceType (val) {
      if (!val) return

      const data = await ConfiguratorApi.availableProxies(this.serviceType).then(r => r.data)
      this.availableProxies = data.items
      this.locationNames = data.locations.reduce((acc, val) => {
        return Object.assign(acc, { [val.key]: val.name })
      }, {})
      this.stacks = [{ key: null, name: 'Любая' }, ...Object.entries(data.stacks).map(([key, name]) => ({ key: key, name: name }))]
      this.stack = this.stacks[0].key

      this.loadCountries()
    }
  },
  async mounted () {
    this.proxySubTypes = await ConfiguratorApi.subTypes().then(r => r.data.items).then(items => items.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {}))
    await this.load()
  },
  methods: {
    async load () {
      try {
        window.sendYandexMetrics('Configurator')
      } catch {}
      try {
        this.serviceTypes = await ConfiguratorApi.types().then(r => r.data.items)
        if (this.serviceTypes.length === 0) return
        this.serviceType = this.serviceTypes[0].key

        const r = await ConfiguratorApi.tariffs().then(r => r.data)
        this.tariffs = r.items
        this.tariff = r.current
        this.tariff = this.tariffs[this.tariffs.length - 1].id

        this.loading = false
      } catch (e) {
        this.error = 'Произошла ошибка при загрузке калькулятора'
      } finally {
        this.operatorLoading = false
      }
    },
    loadCountries () {
      this.template = null
      this.countries = Object.keys(this.availableProxies).map(i => Object.assign({}, { key: i, name: countries[i] }))
      this.country = this.countries[0]?.key
      this.loadTypes()
    },
    loadTypes () {
      this.template = null
      this.types = Object.keys(this.availableProxies[this.country])
        .map(i => Object.assign({}, { key: i, name: this.proxySubTypes[i] }))

      if (this.types.length === 0) {
        this.type = null
        this.amount = 0
        this.maxProxy = 0
      } else {
        if (this.type === null || this.types.map(i => i.key).indexOf(this.type) === -1) {
          if (this.types.some(i => i.key === 'shared')) {
            this.type = 'shared'
          } else {
            this.type = this.types[0].key
          }
        }
        this.loadLocations()
      }
    },
    loadLocations () {
      this.template = null
      if (this.type !== null) {
        this.locations = Object.keys(this.availableProxies[this.country][this.type])
          .map(i => Object.assign({}, { key: i, name: this.locationNames[i] }))
          .sort((i1, i2) => {
            return i1.key === 'mix' ? -1 : 0
          })
        this.location = this.locations[0].key
        this.loadOperators()
      } else {
        this.location = null
        this.amount = 0
        this.maxProxy = 0
      }
    },
    loadOperators () {
      this.template = null
      if (this.location !== null) {
        this.operators = Object.keys(this.availableProxies[this.country][this.type][this.location])
          .map(i => Object.assign({}, { key: i, name: operators[i] })).sort((i1, i2) => {
            return i1.key === 'common' ? -1 : 0
          })

        if (this.operators.length === 0) {
          this.operator = null
          this.amount = 0
          this.maxProxy = 0
        } else {
          // if (this.operators.map(i => i.key).indexOf(this.operator) === -1) {
          //   this.operator = this.operators[0].key
          // }
          this.operator = 'common'

          this.change()
        }
      }
    },
    change () {
      this.price = 0
      this.template = null
      if (this.type === 'arbitration') {
        if (this.operators.length > 1) {
          this.operator = 'common'
        }
      }
      this.fetchPeriodLimits()
    },
    fetchPeriodLimits () {
      ConfiguratorApi.periodLimits(this.type).then(response => {
        this.periodLimits = response.data
        this.adjustPeriodValue()
      })
    },
    adjustPeriodValue () {
      if (this.period < this.periodLimits.min) {
        this.period = this.periodLimits.min
      }
      if (this.period > this.periodLimits.max) {
        this.period = this.periodLimits.max
      }
      this.fetchMaxProxies()
    },
    fetchMaxProxies () {
      if (this.serviceType === null || this.type === null || this.location === null || this.operator === null) return
      this.priceLoading = true
      // ConfiguratorApi.maxProxy(this.kind, this.type, this.stack, this.location, this.operator).then(response => {
      //   this.maxProxy = response.data
      this.maxProxy = Math.floor(Math.random() * 500) + 500
      if (this.amount > this.maxProxy) {
        this.amount = this.maxProxy
      }
      if (this.maxProxy > 1 && this.amount === 0) {
        this.amount = 1
      }

      if (this.maxProxy === 0) {
        this.amount = 0
        this.priceLoading = false
      }

      if (this.amount > 0) {
        this.calculate()
      }
      // }).catch(() => {
      //   this.priceLoading = false
      // })
    },
    calculate () {
      if (this.serviceType === null || this.type === null || this.location === null || this.operator === null || this.amount === 0 || parseInt(this.period) <= 0) return
      this.priceLoading = true
      clearInterval(this.priceLoadingTimeoutHandler)
      this.priceLoadingTimeoutHandler = setTimeout(() => {
        ConfiguratorApi.price(
          this.type,
          `1${this.period}`,
          this.amount,
          this.priceType,
          this.tariff
        ).then(response => {
          this.price = response.data.price
          this.basePrice = response.data.base_price
          this.template = response.data.template
          this.nextTariff = response.data.next_tariff
          this.generateRecommendations()
        }).finally(() => {
          this.priceLoading = false
        })
      }, 500)
    },
    buy () {
      // const order = {
      //   template: this.template,
      //   stack: this.stack,
      //   protocol: 'https',
      //   tcpConnections: this.tcpConnections,
      //   period: this.period + 'd',
      //   amount: this.amount,
      //   operator: this.operator
      // }

      if (this.$refs.form.validate()) {
        info().then(() => {
          document.location = process.env.VUE_APP_OFFICE_URL + '/my-cart/?component=configurator'
          // addOrderToCart(order, this.$notify)
        }).catch(e => {
          if (e === 'unauthenticated') {
            document.dispatchEvent(new CustomEvent('app:login', { detail: null }))
            // document.dispatchEvent(new CustomEvent('app:login', { detail: order }))
          } else {
            this.$notify.toast('Произошла непредвиденная ошибка', { color: 'error' })
          }
        })
      }
    },
    register () {
      document.dispatchEvent(new CustomEvent('app:login', { detail: null }))
    },
    generateRecommendations () {
      this.recommendations = ''

      if (this.nextTariff !== '') {
        this.recommendations = `Для получения дополнительной скидки перейдите на тариф <b>${this.nextTariff}</b>`
      }
    },
    validateDnsValue (val) {
      const addresses = val.split(',').map(i => i.trim())
      for (const i in addresses) {
        if (!validIpAddress(addresses[i])) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style>
.calculator-container {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  text-align: center!important;
}

.calculator-price {
  margin-top: 0.1em;
  margin-bottom: 1em;
  font-size: 24px;
}

.recommendations {
  font-size: 14px;
}
</style>

<style module>
.basePrice {
  margin-right: 0.5em;
  font-size: 0.8em;
  color: gray;
  text-decoration: line-through;
  position: relative;
  left: -20px;
}
</style>
